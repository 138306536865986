import { Component, Input, ViewChild } from '@angular/core';
import { Contact } from 'src/app/services/form.service';
import { VideoSectionComponent } from './video-section/video-section.component';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-fifth-form',
  templateUrl: './fifth-form.component.html',
  styleUrls: ['./fifth-form.component.scss']
})
export class FifthFormComponent {
  @ViewChild(VideoSectionComponent) videoSectionComponent!: VideoSectionComponent;

  @Input() contact!: Contact;

  viewIsIframe: boolean = false;

  constructor(private viewIframeService: ViewIframeService) {
    this.viewIframeService.viewIsIframe$
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.viewIsIframe = value;
      });
  }

  openWppLink() {
    const { id } = this.contact;
    let newId: any = parseInt(id!);
    newId = newId + 16 + 452;
    newId = `10${newId.toString(16)}`;

    window.open(`https://api.whatsapp.com/send?text=*Voc%C3%AA%20sabia%20que%20pode%20economizar%20muito%20na%20sua%20conta%20de%20luz%20na%20Black%20Bulbe?%20Eu%20j%C3%A1%20estou%20economizando!*%0A%0ASe%20voc%C3%AA%20se%20cadastrar%20agora,%20garante%2050%%20de%20desconto%20na%20primeira%20fatura%20e,%20nos%20meses%20seguintes,%20at%C3%A9%2015%%20de%20desconto%20todo%20m%C3%AAs!%20E%20o%20melhor:%20sem%20obras%20ou%20burocracia,%20%C3%A9%20s%C3%B3%20cadastrar%20e%20come%C3%A7ar%20a%20economizar.%0A%0AVoc%C3%AA%20tamb%C3%A9m%20ganha%20R$50%20no%20Pix%20indicando%20seus%20amigos!%20E%20mais%20R$250%20de%20b%C3%B4nus%20a%20cada%205%20indica%C3%A7%C3%B5es%20v%C3%A1lidas.%0A%0A%C3%89%20s%C3%B3%20em%20novembro!%20Fa%C3%A7a%20seu%20cadastro%20e%20aproveite%20essas%20condi%C3%A7%C3%B5es%20imperd%C3%ADveis!%0A%0AAcesse%20esse%20link%20e%20economize%20tamb%C3%A9m:%20https%3A%2F%2Fassine.bulbeenergia.com.br%2F%23%2Fshare%2F${newId}`)
  }
}
