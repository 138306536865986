import { Component } from '@angular/core';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class SectionAppComponent {
  openApp(n: number) {
    switch (n) {
      case 1:
        window.open('https://app.bulbe.com.br/ios');
        break;
      case 2:
        window.open('https://app.bulbe.com.br/android');
        break;

      default:
        break;
    }
  }
}
