<div class="toast-wrapper">
  <ng-container *ngFor="let toast of toasts.reverse()">
    <div #toast class="toast-notification-wpp-container" [class.show-toast-notification]="toast.show" [class.hide-toast-notification]="!toast.show">
      <div
        class="toast-notification-card border"
        [class]="[toast.borderStyle, toast.type]"
      >
        <button class="btn-close" *ngIf="toast.id" (click)="dismissToast(toast.id)">
          <app-icons name="x" color="#29333D"></app-icons>
        </button>
        <p class="title" *ngIf="toast.title">{{ toast.title }}</p>
        <p class="text" *ngIf="toast.text">{{ toast.text }}</p>
        <ng-template *ngIf="toast.template" [ngTemplateOutlet]="toast.template"></ng-template>
      </div>
    </div>
  </ng-container>
</div>
