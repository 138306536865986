import { Component, ViewChild } from '@angular/core';

import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
    selector: 'app-video-section', // Nome da tag que será usada no HTML
    templateUrl: './video-section.component.html', // Caminho para o template HTML
    styleUrls: ['./video-section.component.scss'] // Caminho para os estilos CSS
})
export class VideoSectionComponent {
    @ViewChild('modal') modal!: ModalComponent; // Referência ao modal


    constructor(
        private modalService: ModalService,
    ) {

    }

    openModal() {
        this.modalService.open();
        console.log('open')
    }

}
