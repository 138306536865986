<div class="card" [ngClass]="{'iframe': viewIsIframe}" *ngIf="!showUploadInvoice">
  <div class="wrapper-section">
    <app-title title="Cadastro identificado" subtitle="Vamos mantê-lo informado pelo nosso aplicativo, e-mail e WhatsApp."></app-title>
    <app-subtitle>
      <span class="subtitle">
        Identificamos que você já finalizou seu cadastro
      </span>
    </app-subtitle>

    <div style="width: 100%;">
      <p>Como podemos ajudar você?</p>
    </div>
    <div class="wrapper-btn">
      <button (click)="goToUploadInvoice()">
        Acelerar minha conexão
        <app-icons name="arrow-right" color="#3E4D5B"></app-icons>
      </button>

      <button (click)="goToNextStep(3)">
        Status da minha Adesão
        <app-icons name="arrow-right" color="#3E4D5B"></app-icons>
      </button>

      <button (click)="goToNextStep(1)">
        Falar com o suporte
        <app-icons name="arrow-right" color="#3E4D5B"></app-icons>
      </button>
    </div>
  </div>

  <app-download-app></app-download-app>
</div>

<div class="card content-upload-invoice" *ngIf="showUploadInvoice && !sendImageInvoice">
  <app-upload-invoice
    class="upload-invoice-element"
    labelBtnCancelUpload="Voltar"
    (clickSendImageInvoice)="sendInvoiceImage($event)"
    (clickBackStage)="handleCancelUploadImageInvoice()"
    (emitUrlImage)="openModalZoomImage($event)"
    [configBtnOutline]="{
      label: 'Voltar',
      nameIcon: 'arrow-left',
      colorIcon: '#3E4D5B'
    }"
    [configBtnPrimary]="{
      label: 'Enviar',
      nameIcon: 'arrow-right',
      colorIcon: '#FFFFFF'
    }"
  />
</div>

<app-modal-zoom-image [base64]="currentImageZoom"/>

