import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-wrapper-term-policy',
  templateUrl: './wrapper-term-policy.component.html',
  styleUrls: ['./wrapper-term-policy.component.scss']
})

export class WrapperTermPolicyComponent {
  @Output() scrolledToBottom = new EventEmitter<void>();
  @ViewChild('term', { static: true }) termElement!: ElementRef;

  isScrollDownButtonDisabled: boolean = false;

  onScroll(event: any) {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;
    this.isScrollDownButtonDisabled = false;

    if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
      this.scrolledToBottom.emit();
      this.isScrollDownButtonDisabled = true;
    }
  }

  onScrollDownButtonClick() {
    if (this.termElement) {
      const element = this.termElement.nativeElement;
      element.scrollTop = element.scrollHeight;
    }
  }
}
