<div class="card" [ngClass]="{'iframe': viewIsIframe}">
  <div class="wrapper-section">
    <app-title [currentStep]="2" title="Valide seu número de celular."></app-title>

    <app-subtitle>
      <span class="subtitle">
        Digite o código enviado por SMS para o número:
      </span>
    </app-subtitle>
    <p class="phone">
      {{phone | mask: '(00) 90000-0000'}}
    </p>

    <form [formGroup]="secondForm" class="second">
      <label for="code1" class="form-label">Código de acesso <span class="prop-required">*</span></label>
      <div>
        <input
          id="code1"
          (paste)="onPaste($event)"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          (keyup)="onDigitInput($event, 'code2')"
          (keydown)="onKeydown($event, 'code1')"
          maxLength="1"
          class="input lg code"
          formControlName="code1"
          placeholder="0">

        <input
          id="code2"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          (keyup)="onDigitInput($event, 'code3')"
          (keydown)="onKeydown($event, 'code1')"
          maxLength="1"
          class="input lg code"
          formControlName="code2"
          placeholder="0">

        <input
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          (keyup)="onDigitInput($event, 'code4')"
          (keydown)="onKeydown($event, 'code2')"
          maxLength="1"
          class="input lg code"
          formControlName="code3"
          id="code3"
          placeholder="0">

        <input
          id="code4"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          (keyup)="onDigitInput($event, 'code5')"
          (keydown)="onKeydown($event, 'code3')"
          maxLength="1"
          class="input lg code"
          formControlName="code4"
          placeholder="0">


        <input
          id="code5"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          (keyup)="onDigitInput($event, 'code6')"
          (keydown)="onKeydown($event, 'code4')"
          maxLength="1"
          class="input lg code"
          formControlName="code5"
          placeholder="0">

        <input
          id="code6"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          (keyup)="onDigitInput($event, '')"
          (keydown)="onKeydown($event, 'code5')"
          (keyup.enter)="onSubmit()"
          maxlength="1"
          class="input lg code"
          formControlName="code6"
          placeholder="0">
        </div>
    </form>

    <p *ngIf="showError1" class="error-message sm">Código expirado.</p>
    <p *ngIf="showError2" class="error-message md">Código inválido.</p>

    <!-- construir o timer aqui -->
    <p class="btn-countdown" *ngIf="countdown != 0 && countdown != -1">
      <u>Reenviar código ({{countdown}}s)
      </u>
    </p>

    <p class="btn-countdown reset" *ngIf="countdown == 0" (click)="resendCode()">
      <u>Reenviar código</u>
    </p>
  </div>

  <div>
    <mat-divider></mat-divider>
    <div class="button-wrapper-section">
      <button class="btn black-bulbe-rollback lg no-responsive-icon" (click)="back()">
        <app-icons [showInResponsiveScreen]="false" name="arrow-left" color="#536679"></app-icons>
        <span>Voltar</span>
      </button>

      <button class="btn black-bulbe lg no-responsive-icon" [disabled]="secondForm.invalid" (click)="onSubmit()">
        <span>Próximo</span>
        <app-icons [showInResponsiveScreen]="false" name="arrow-right" [color]="secondForm.invalid ? '#C2CCD6;' : '#000000'"></app-icons>
      </button>
    </div>
  </div>
</div>
