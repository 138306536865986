import { Component, Input, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatStepper } from '@angular/material/stepper';
import { ViewIframeService } from '../services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-multiple-installations-stepper',
  templateUrl: './multiple-installations-stepper.component.html',
  styleUrls: ['./multiple-installations-stepper.component.scss']
})
export class MultipleInstallationsStepperComponent {

  @Input() contact_id?: string;
  @ViewChild('stepper3') stepper3!: MatStepper;

  selectedStepIndex!: number;
  dealsUpdated: boolean = false;
  viewIsIframe: boolean = false;

  constructor(private viewIframeService: ViewIframeService) {
    this.viewIframeService.viewIsIframe$
    .pipe(takeUntilDestroyed())
    .subscribe((value) => {
      this.viewIsIframe = value;
    })
  }

  receiveDealUpdates(event: any) {
    this.dealsUpdated = event;
  }

}
