<div class="wrapper-footer">
  <div class="content">
    <img class="bulbe-logo" src="../../../assets/images/logo-base.png" alt="Bulbe" />
    <div class="text-copy">
      <p>Sua próxima energia,</p>
      <p>na palma da sua mão.</p>
    </div>

    <div class="text-subtitle">
      <p>
        Acompanhe seu consumo, controle sua conta de luz,
      </p>
      <p>
        e <b> veja sua economia no App Bulbe.</b>
      </p>
    </div>

    <p class="text-clients">+ de 40 mil clientes</p>

    <div class="avaliation-wrapper">
      <img class="bulbe-app-avaliations-img" src="../../../assets/images/bulbe-app-avaliations.svg" />
    </div>

    <div class="wrapper-img">
      <button (click)="openApp(2)">
        <img src="../../../assets/images/gplay.png" />
      </button>

      <button (click)="openApp(1)">
        <img src="../../../assets/images/app.svg" />
      </button>
    </div>
  </div>
</div>
