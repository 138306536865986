<div class="wrapper" [ngClass]="{'iframe': viewIsIframe}">
  <div class="card-content">
    <div class="custom-progress-bar" *ngIf="!viewIsIframe">
      <div class="progress-bar-fill" [ngStyle]="{'background-color': dealsUpdated ? '#26D07C' : '#0762C7'}"
        [style.width]="'100' + '%'"></div>
    </div>

    <mat-stepper linear #stepper3 [selectedIndex]="selectedStepIndex" [animationDuration]="'0'">
      <mat-step>
        <app-select-installarions [contact_id]="contact_id"
          (sendUpdatedDeals)="receiveDealUpdates($event)"></app-select-installarions>
      </mat-step>
    </mat-stepper>

    <div class="wrapper-img" *ngIf="!viewIsIframe">
      <img src="../../assets/images/logo-base.svg" alt="Logo Bulbe Energia">
      <span>Energia limpa e mais barata.</span>
    </div>
  </div>
</div>
