<div id="video-section">
  <div class="container">
    <div class="wrapper-title">
      <h2>Conheça a Bulbe energia</h2>
      <div class="wrapper-icon">
        <a href="https://www.youtube.com/watch?v=okG4n7irP9Q" target="_blank" class="icon-btn">
          <img src="assets/images/play-icon.svg" />
        </a>
      </div>
    </div>
  </div>
</div>
