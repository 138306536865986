<div class="card" [ngClass]="{'iframe': viewIsIframe}">
  <div class="wrapper-section">
    <app-title title="Economia contratada com sucesso!"
      subtitle="Vamos mantê-lo informado pelo nosso aplicativo, e-mail e WhatsApp."></app-title>

    <div class="content-wrapper">
      <app-video-section></app-video-section>
      <div class="refer-and-win-section">
        <div class="refer-and-win-header">
          <img class="left-image mobile" src="/assets/images/indique-e-ganhe-mobile.svg" />
          <img class="left-image desktop" src="/assets/images/indique-e-ganhe-desktop.svg" />
          <button class="desktop" mat-flat-button (click)="openWppLink()">
            <span class="share-link">
              Compartilhar
              <app-icons name="whatsapp" color="#FFF"></app-icons>
            </span>
          </button>
        </div>
        <div>
          <p>
            Compartilhe o seu link exclusivo com amigos e familiares. A cada adesão válida, você ganha R$50 no PIX,
            disponível para saque.
          </p>
          <p>
            E o melhor: a cada 5 adesões válidas, você ainda recebe um bônus de R$250 via PIX!
          </p>
          <a href="../../assets/regulamento-blackbulbe.pdf" download="Regulamento.pdf">Confira o regulamento</a>
        </div>
        <button class="mobile" mat-flat-button (click)="openWppLink()">
          <span class="share-link">
            Compartilhar
            <app-icons name="whatsapp" color="#FFF"></app-icons>
          </span>
        </button>
      </div>
    </div>
  </div>
  <app-download-app></app-download-app>
</div>
