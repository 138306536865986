import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewIframeService {
  private viewIsIframeSubject = new BehaviorSubject<boolean>(false);
  viewIsIframe$ = this.viewIsIframeSubject.asObservable();

  constructor() {}

  checkIsIframe() {
    this.viewIsIframeSubject.next(window.top !== window.self);
  }
}
