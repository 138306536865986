import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class ArrowComponent {
  @Input() color: string = "red";
  @Input() name: string = "arrow-left";
  @Input() showInResponsiveScreen: boolean = true;
}
