import { Injectable } from '@angular/core';
import { formatFileSize } from 'src/app/utils/format';
import { UploadImageService } from '../upload-image/upload-image.service';
import { CompressFileService } from '../compress-file/compress-file.service';
import { LoaderService } from 'src/app/shared/loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ProcessFileService {
  isFormatImage: boolean = false;

  constructor(
    private uploadImageService: UploadImageService,
    private compressFileService: CompressFileService,
    private loaderService: LoaderService,
  ) {}

  async processFile(
    imageInput: HTMLInputElement,
  ): Promise<{ success: boolean; imageData?: any; error?: string }> {
    const file: File = imageInput.files![0];

    const formatImage = file.type.split('/')[1];

    try {
      this.loaderService.setLoading(true);
      let base64String;
      let compressedFile: Blob;

      if (formatImage == 'pdf') {
        compressedFile = await this.compressFileService.compressPdf(file);
      } else {
        compressedFile = await this.compressFileService.compressImage(file);
      }

      base64String =
        await this.compressFileService.blobToBase64(compressedFile);

      const maxSizeFile = 2500000; // 2.5MB

      // Limita o tamanho do arquivo em 2.5MB
      if (compressedFile.size > maxSizeFile) {
        this.uploadImageService
          .setErroImageInvoiceSubject(`Tamanho máximo do arquivo é de ${formatFileSize(maxSizeFile)},
          e seu arquivo tem ${formatFileSize(file.size)}`);

        return { success: false };
      }

      const imageData = {
        base64: base64String,
        formatImage: formatImage,
        nameImage: file.name,
        size: compressedFile.size,
      };

      this.uploadImageService.resetErroImageInvoiceSubject();

      this.uploadImageService.setImageInvoiceSubject(imageData);

      formatImage !== 'pdf'
        ? (this.isFormatImage = true)
        : (this.isFormatImage = false);

      return { success: true, imageData };
    } catch (error) {
      this.uploadImageService.setErroImageInvoiceSubject(
        'Erro ao enviar a imagem.',
      );

      return { success: false, error: 'Erro ao enviar a imagem.' };
    } finally {
      this.loaderService.setLoading(false);
    }
  }
}
