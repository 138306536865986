<div class="custom-progress-bar">
  <div *ngIf="!isStepSuccess" class="progress-bar-fill"
    [ngStyle]="{'background-color': '#FF9E1B'}"
    [style.width]="'100' + '%'">
  </div>

  <div
    *ngIf="isStepSuccess" class="progress-bar-fill"
    [ngStyle]="{'background-color': '#26D07C'}"
    [style.width]="'100' + '%'">
  </div>
</div>
<mat-stepper linear #stepper2 [selectedIndex]="selectedStepIndex" [animationDuration]="'0'">
  <mat-step *ngIf="contact.aceite_do_termo_de_adesao == 'false'">
    <app-fourth-form
      [contactAlreadyExist]="contactAlreadyExist"
      [incompleteDeal]="incompleteDeal"
      [contact]="contact"
      (nextStep)="nextStep($event)"
      (contactUpdated)="receiveContact($event)">
    </app-fourth-form>
  </mat-step>

  <mat-step>
    <app-choose-action
      *ngIf="contact.aceite_do_termo_de_adesao == 'true'"
      [contact]="contact"
      (isStepSuccess)="isStepSuccess = $event"
      (nextStep2)="nextStep($event)">
    </app-choose-action>

    <app-fifth-form *ngIf="contact.aceite_do_termo_de_adesao == 'false' || isStepSuccess" [contact]="contact"></app-fifth-form>
  </mat-step>

  <mat-step>
  </mat-step>

  <mat-step>
    <app-third-form
      [contact]="contact"
      [contactAlreadyExist]="contactAlreadyExist"
      (nextStep)="goToFinalStep($event)"
      *ngIf="step == 4">
    </app-third-form>
  </mat-step>

  <mat-step>
    <app-choose-action *ngIf="dealCpfOrCnpjDuplicated" [contact]="contact" [dealCpfOrCnpjDuplicated]="dealCpfOrCnpjDuplicated"></app-choose-action>

    <app-fifth-form *ngIf="!dealCpfOrCnpjDuplicated" [contact]="contact"></app-fifth-form>
  </mat-step>
</mat-stepper>
