import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewIframeService } from 'src/app/services/view-iframe/view-iframe.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  @Input() title: string = "";
  @Input() subtitle: string = "";
  @Input({ required: false }) currentStep?: number;
  @Input({ required: false }) showHelpText: boolean = false;
  viewIsIframe: boolean = false;
  whatsappLink = 'https://wa.me//31984476424/?text=Ol%C3%A1%21%20Quero%20falar%20com%20um%20consultor%20para%20economizar%20com%20a%20Bulbe.'

  constructor(private viewIframeService: ViewIframeService) {
    this.viewIframeService.viewIsIframe$
    .pipe(takeUntilDestroyed())
    .subscribe((value) => {
      this.viewIsIframe = value;
    })
  }
}
