import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormStepperComponent } from './form-stepper/form-stepper.component';
import { MultipleInstallationsStepperComponent } from './multiple-installations-stepper/multiple-installations-stepper.component';
import { CpfNotFoundStepperComponent } from './cpf-not-found-stepper/cpf-not-found-stepper.component';

const routes: Routes = [
  {
    path:"formulario_de_adesao",
    component: FormStepperComponent
  },
  {
    path: "multiplas_instalacoes/:contact_id",
    component: MultipleInstallationsStepperComponent
  },
  {
    path: "cpf_nao_encontrado/:contact_id",
    component: CpfNotFoundStepperComponent
  },
  { path: '',   redirectTo: '/formulario_de_adesao', pathMatch: 'full' },
  { path: '**', redirectTo: '/formulario_de_adesao' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
