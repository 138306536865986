<div class="upload-fatura">
  <div class="section">
    <app-title title="Acelere sua conexão."></app-title>

    <p class="subtitle">
      <strong>Para acelerar a sua instalação</strong> envie uma foto de uma conta de luz Cemig da instalação cadastrada.
    </p>

    <form>
      <label class="form-label">Conta de luz <span class="prop-required">*</span></label>
      <div *ngIf="!imageInvoice.nameImage" class="wrapper-btn-upload">
        <label for="file-upload-desktop" class="btn secondary lg" id="desktop-file">
          Enviar conta de luz
          <app-icons name="image" color="#FFF"></app-icons>
          <input
            #imageInputDesktop
            id="file-upload-desktop"
            type="file"
            accept="image/*, application/pdf"
            (change)="processFile(imageInputDesktop)"
          >
        </label>

        <label for="file-upload-mobile" class="btn secondary lg" id="mobile-cam">
          <app-icons name="camera" color="#FFF"></app-icons>
            Enviar conta de luz
          <input
            #imageInputMobile
            id="file-upload-mobile"
            type="file"
            accept="image/*, application/pdf"
            capture
            (change)="processFile(imageInputMobile)"
          >
        </label>
      </div>

      <div *ngIf="imageInvoice.nameImage" class="wrapper-image-invoice">
        <div
          class="content-image"
          [ngClass]="{'cursor': isFormatImage}"
          (click)="handleOpenModalZoomImage(imageInvoice.base64)"
        >
          <app-icons name="image-invoice-default" color="#08068D"></app-icons>
          <span>minha-fatura.{{ imageInvoice.formatImage }}</span>
        </div>

        <button class="btn-close" (click)="resetSendImageInvoice()">
          <app-icons name="x" color="#8699AC"></app-icons>
        </button>
      </div>
    </form>

    <div class="wrapper-errors">
      <span class="message-error">{{ errorUploadImage }}</span>
    </div>


    <div class="observations">
      <div class="content-text">
        <p class="title-observations">Certifique-se que:</p>
        <ul class="list">
          <li class="item-list">A fatura está enquadrada, sem cortes na imagem.</li>
          <li class="item-list">Os textos estão legíveis.</li>
          <li class="item-list">A imagem não está embaçada.</li>
        </ul>
      </div>
    </div>
  </div>

  <div>
    <mat-divider color="#C2CCD6"></mat-divider>
    <div class="section wrapper-btn-footer">
      <button class="btn black-bulbe-rollback md no-responsive-icon" (click)="handleClickBack()">
        <app-icons
          *ngIf="configBtnOutline.nameIcon"
          [name]="configBtnOutline.nameIcon"
          color="#536679"
          [showInResponsiveScreen]="false"
        />
        {{ configBtnOutline.label }}
      </button>
      <button
        class="btn black-bulbe md no-responsive-icon"
        (click)="handleSendInvoiceImage()"
        [disabled]="buttonIsDisabled"
      >
        <span>
          {{ configBtnPrimary.label }}
        </span>
      <app-icons
        *ngIf="configBtnPrimary.nameIcon"
        [name]="configBtnPrimary.nameIcon"
        [color]="buttonIsDisabled ? '#C2CCD6' : '#000000'"
        [showInResponsiveScreen]="false"
      />
      </button>
    </div>
  </div>
</div>
