import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-indication-win',
  templateUrl: './indication-win.component.html',
  styleUrls: ['./indication-win.component.scss']
})
export class SectionIndicationWinComponent {
  @Output() clickEmit = new EventEmitter();

  handleEmit() {
    this.clickEmit.emit();
  }
}
