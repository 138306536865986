import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkingHoursChatService {
  private isActive: boolean = false;
  private holidays: Date[] = [];

  constructor() {
    this.initializeHolidays()
    this.updateFlag();
  }

  private updateFlag(): void {
    const now = new Date();
    const day = now.getDay(); // 0 (Domingo) - 6 (Sábado)
    const hour = now.getHours();
    const isHoliday = this.isHoliday(now);

    if (isHoliday) {
      this.isActive = false;
      return;
    }

    switch (day) {
      case 1: // Segunda-feira
        this.isActive = hour >= 8 && hour < 18;
        break;
      case 2: // Terça-feira
      this.isActive = hour >= 8 && hour < 20;
        break;
      case 3: // Quarta-feira
      this.isActive = hour >= 8 && hour < 20;
        break;
      case 4: // Quinta-feira
        this.isActive = hour >= 8 && hour < 20;
        break;
      case 5: // Sexta-feira
        this.isActive = hour >= 8 && hour < 17;
        break;
      default: // Sábado e Domingo
        this.isActive = false;
    }
  }

  private isHoliday(date: Date): boolean {
    return this.holidays.some(holiday =>
      holiday.getDate() === date.getDate() &&
      holiday.getMonth() === date.getMonth() &&
      holiday.getFullYear() === date.getFullYear()
    );
  }

  private initializeHolidays(): void {
    const year = new Date().getFullYear();
    this.holidays = [
      new Date(year, 0, 1), // Confraternização Universal (1 de Janeiro)
      new Date(year, 3, 21), // Tiradentes (21 de Abril)
      new Date(year, 4, 1), // Dia do Trabalhador (1 de Maio)
      new Date(year, 8, 7), // Independência do Brasil (7 de Setembro)
      new Date(year, 9, 12), // Nossa Senhora Aparecida (12 de Outubro)
      new Date(year, 10, 2), // Finados (2 de Novembro)
      new Date(year, 10, 15), // Proclamação da República (15 de Novembro)
      new Date(year, 11, 25), // Natal (25 de Dezembro)
      new Date(year, 8, 15),
      new Date(year, 11, 20),
      new Date(year, 12, 8),
    ];
  }

  public getIsActive(): boolean {
    return this.isActive;
  }
}
