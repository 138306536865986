<div class="wrapper" [ngClass]="{'iframe': viewIsIframe}">
  <div class="card-content">
    <div class="custom-progress-bar" *ngIf="!viewIsIframe">
      <div class="progress-bar-fill"
        [ngStyle]="{'background-color': stepper4.selectedIndex == 1 ? '#26D07C' : 'red'}"
        [style.width]="'100' + '%'">
      </div>
    </div>

    <mat-stepper linear #stepper4 [animationDuration]="'0'">
      <mat-step>
        <app-third-form [cpfNotFound]="cpfNotFound" [deal]="deal" [contact]="contact"
          [contactAlreadyExist]="contactAlreadyExist" (nextStep4)="nextStep($event)"></app-third-form>
      </mat-step>
      <mat-step>
        <app-fifth-form [contact]="contact"></app-fifth-form>
      </mat-step>
    </mat-stepper>

    <div class="wrapper-img" *ngIf="!viewIsIframe">
      <img src="../../assets/images/logo-base.svg" alt="Logo Bulbe Energia">
      <span>Energia limpa e mais barata.</span>
    </div>
  </div>
</div>
