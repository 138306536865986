<div class="wrapper">
  <div class="title-wrapper">
    <h2 [ngClass]="{'iframe': viewIsIframe}">{{ title }}</h2>
    <span class="help-text" *ngIf="showHelpText">
      Em caso de dúvidas,
        <a
          href="{{ whatsappLink }}"
          target="_blank">
          clique aqui.
        </a>
    </span>
    <h6>{{ subtitle }}</h6>
  </div>
  <span *ngIf="currentStep !== null && currentStep !== undefined">{{ currentStep }} / 4</span>
</div>
